import { schema } from 'normalizr'

export default (ctx, inject) => {
  const relationships = {
    addressHistory: new schema.Entity('addressHistory'),
    contacts: new schema.Entity('contacts'),
    ditches: new schema.Entity('ditches'),
    divisions: new schema.Entity('divisions'),
    diversionPoints: new schema.Entity('diversionPoints'),
    producesFu: new schema.Entity('producesFu'),
    farmunits: new schema.Entity('farmunits'),
    filters: new schema.Entity('filters'),
    flags: new schema.Entity('flags'),
    sectionSchemas: new schema.Entity('sectionSchemas'),
    notes: new schema.Entity('notes'),
    offlineRequests: new schema.Entity('offlineRequests'),
    omsettings: new schema.Entity('omsettings'),
    ownershipHistory: new schema.Entity('ownershipHistory'),
    orders: new schema.Entity('orders'),
    producerHistory: new schema.Entity('producerHistory'),
    states: new schema.Entity('states'),
    syncStats: new schema.Entity('syncStats'),
    syncHistory: new schema.Entity('syncHistory'),
    usages: new schema.Entity('usages'),
    users: new schema.Entity('users'),
    announcements: new schema.Entity('announcements')
  }

  relationships.contacts.define({
    producesFu: [relationships.producesFu],
    producesDp: [relationships.diversionPoints]
  })
  relationships.announcements.define({})
  relationships.diversionPoints.define({
    farmunit: relationships.farmunits,
    ditch: relationships.ditches
  })
  relationships.divisions.define({})
  relationships.ditches.define({})
  relationships.farmunits.define({
    producer: relationships.contacts,
    usages: [relationships.usages],
    orders: [relationships.orders],
    flags: [relationships.flags],
    diversionPoints: [relationships.diversionPoints],
    owners: [relationships.contacts]
  })
  relationships.filters.define({})
  relationships.flags.define({})
  relationships.sectionSchemas.define({})
  relationships.notes.define({
    contact: relationships.contacts
  })
  relationships.offlineRequests.define({})
  relationships.omsettings.define({})
  relationships.orders.define({})
  relationships.ownershipHistory.define({})
  relationships.producerHistory.define({})
  relationships.addressHistory.define({})
  relationships.states.define({})
  relationships.syncStats.define({})
  relationships.syncHistory.define({})
  relationships.usages.define({})
  relationships.users.define({})

  inject('relationships', relationships)
}
